import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { cloneDeep } from 'lodash-es';
import { EMPTY, Observable } from 'rxjs';
import { expand, map } from 'rxjs/operators';

import { dataURL, spatialSearchURL } from '@core/http/endpoints';
import { InlineField } from '@modules/map-panel/object-panel/object-panel.actions';
import { RowsData } from '@modules/map-panel/store/map-panel.reducer';
import { SearchQuery } from '@modules/spatial-search/spatial-search';

import {
  GogisColumn,
  ModelNormalize,
  ObjectResponse,
  TableResponse,
} from '../models';

/** Service to get tables groups, apps, models */
@Injectable({
  providedIn: 'root',
})
export class TablesService {
  /** @ignore */
  constructor(private http: HttpClient) {}

  /**
   * http request to get a table with the full url from backend
   * Use to fetch more rows
   */
  getTableByUrl(url: string): Observable<TableResponse<ObjectResponse>> {
    return this.http.get<TableResponse<ObjectResponse>>(`${url}`);
  }

  getTableAdditionalData(
    contentType: number,
    offset?: number,
    orderParam?: string,
    rows?: ObjectResponse[],
    modelCols?: GogisColumn[],
    limit?: number,
  ): Observable<{ rows: ObjectResponse[]; next: string; count: number }> {
    let url = `${dataURL}/${contentType}?limit=${limit || 100}`;
    url += offset ? '&offset=' + offset : '';
    url += orderParam ? '&ordering=' + orderParam : '';
    url += this.createFilterParameters(modelCols)
      ? `&${this.createFilterParameters(modelCols)}`
      : '';
    return this.http.get<TableResponse<ObjectResponse>>(url).pipe(
      map((dataResponse) => {
        dataResponse.results.map((object) => {
          object.dataType = RowsData.DATA;
        });

        if (rows) {
          let tempRows = cloneDeep(rows);

          //Update rows and don't break order
          dataResponse.results.forEach((item) => {
            tempRows = tempRows.filter((row) => item.id !== row.id);
          });
          tempRows.splice(offset || 0, 0, ...dataResponse.results);

          return {
            rows: tempRows,
            next: dataResponse.next,
            count: dataResponse.count,
          };
        }

        return {
          rows: dataResponse.results,
          next: dataResponse.next,
          count: dataResponse.count,
        };
      }),
    );
  }

  getTableData(
    contentType: number,
    model: ModelNormalize,
    searchQuery?: SearchQuery,
    inlineField?: InlineField,
  ): Observable<{ rows: ObjectResponse[]; next: string; objectCount: number }> {
    if (searchQuery) {
      return this.getTableDataFromSearch(searchQuery);
    }
    let url = `${dataURL}/${contentType}?limit=100`;
    url +=
      model.columns && this.createFilterParameters(model.columns)
        ? `&${this.createFilterParameters(model.columns)}`
        : '';
    url += inlineField
      ? `&${inlineField.field}=${inlineField.targetObject}`
      : '';

    if (inlineField?.typeField) {
      url += `&${inlineField.typeField}=${inlineField.targetModelID}`;
    }

    return this.http.get<TableResponse<ObjectResponse>>(url).pipe(
      map((dataResponse) => {
        dataResponse.results.map((object) => {
          object.dataType = RowsData.DATA;
        });

        return {
          rows: dataResponse.results,
          next: dataResponse.next,
          objectCount: dataResponse.count,
        };
      }),
    );
  }

  getTableDataFromSearch(
    searchQuery: SearchQuery,
  ): Observable<{ rows: ObjectResponse[]; next: string; objectCount: number }> {
    return this.http
      .post<
        TableResponse<ObjectResponse>[]
      >(`${spatialSearchURL}/?data_format=light`, searchQuery)
      .pipe(
        map((dataResponse) => {
          dataResponse[0].results.map((object) => {
            object.dataType = RowsData.RESULTS;
          });

          return {
            rows: dataResponse[0].results,
            next: null,
            objectCount: null,
          };
        }),
      );
  }

  getTableGeometries(
    contentType: number,
    hasGeom: boolean,
    modelCols: GogisColumn[],
  ): Observable<TableResponse<ObjectResponse>> {
    let url = `${dataURL}/${contentType}/?fields=id${
      hasGeom ? ',the_geom,object_title' : ''
    }&limit=1000&offset=100`;
    url += this.createFilterParameters(modelCols)
      ? `&${this.createFilterParameters(modelCols)}`
      : '';
    return this.http
      .get<TableResponse<ObjectResponse>>(url)
      .pipe(
        expand(({ next }) =>
          next !== null ? this.getTableByUrl(next) : EMPTY,
        ),
      );
  }

  private createFilterParameters(columns: GogisColumn[]): string {
    let parameter = '';

    columns?.forEach((column) => {
      if (
        column.filterValue ||
        column.filterSelected === 'isnotnull' ||
        column.filterSelected === 'isnull'
      ) {
        parameter += parameter ? '&' : '';
        const filterValue =
          column.filterSelected === 'isnotnull'
            ? 'false'
            : column.filterSelected === 'isnull'
              ? 'true'
              : column.filterValue;
        const filterType =
          column.filterSelected === 'exact'
            ? ''
            : column.filterSelected === 'isnotnull'
              ? '__isnull'
              : `__${column.filterSelected}`;
        parameter += `${column.field
          .toString()
          .replace('_displayname', '')}${filterType}=${filterValue}`;
      }
    });

    return parameter;
  }
}
